import HomeHeader from "../components/Home/HomeHeader";
import MainApp from "../components/Home/MainApp";

const Home = () => {
  return (
    <div className="home">
      <HomeHeader />
      <div className="header-info">
        <div className="header-textbox">
          <p>
            Please select which Easter service you plan to attend, and note that
            sixth grade and above will be joining us in our main services. With
            high attendance expected, please plan to arrive early to secure your
            seat. We look forward to celebrating our Risen Savior together!
          </p>
          <p>
            In addition to our four services listed below, we will also be
            holding a Sunrise Service at 6:30am at the Yucaipa Community Park.
            This service does not require a reservation.
          </p>
        </div>
      </div>
      <MainApp />
    </div>
  );
};

export default Home;
